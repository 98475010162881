import { Data, IFaqDTO } from '@/interfaces';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class SubscriptionStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'SubscriptionStore', properties: ['subscriptionPlan',] });
  }
  subscriptionPlan: any = [];
  

  setSubscriptionPlan = async (subscriptionPlan: any) => {
    runInAction(async () => {
      this.subscriptionPlan = subscriptionPlan;
    });
  }; 
}
