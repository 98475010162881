import React from 'react';
import Modal from '../Modal';
import { MimaButton, MimaText } from '..';
import { userStore } from '@/stores';
import { useRouter } from 'next/navigation';
import constant from '@/utils/constant';

interface LogoutProps {
  closeModal: () => void;
}

const Logout: React.FC<LogoutProps> = ({ closeModal }) => {
  const router = useRouter();

  const logoutHandler = () => {
    userStore.setLoggedIn(false);
    // localStorage.setItem('fashUserLogin', JSON.stringify(false));
    router.push(constant.Routes.Login);
  };

  return (
    <Modal closeModal={closeModal}>
      <MimaText variant="subtitle" mb={4} color="var(--color-primary)">
        Are you sure you <br /> want to log out?
      </MimaText>
      <div className="modal__beside">
        <MimaButton title="Yes" width={18} onClick={logoutHandler} />
        <MimaButton title="No" width={18} onClick={closeModal} buttonColor="var(--color-dark-3)" />
      </div>
    </Modal>
  );
};

export default Logout;
