import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { searchStyles } from './styles';
import { BiSearchAlt } from 'react-icons/bi';

interface SearchProps {
  placeholder?: string;
  id?: string;
  name?: string;
  onBlur?: () => void;
  disabled?: boolean;
  variant?: 'regular' | 'wide' | 'dropdown';
  width?: any;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  input: any[];
  //   output: (data: any[]) => void;
  output: Dispatch<SetStateAction<any[]>>;
  loading?: boolean;
  handleFilter: (item: any, searchQuery: string | null | undefined) => boolean;
  showIcon?: boolean;
  setSearchQuery?: (query: string) => void;
}

const MimaSearch: React.FC<SearchProps> = ({
  placeholder,
  onBlur,
  disabled = false,
  variant = 'regular',
  width,
  id,
  mt,
  mr,
  mb,
  ml,
  loading = false,
  showIcon = true,
  input,
  output,
  handleFilter,
  ...props
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const onOutputReady = useCallback(
    (data: any[]) => {
      if (!Array.isArray(input)) {
        return output([]);
      }
      output(data);
    },
    [input, output],
  );

  useEffect(() => {
    const multiArraySearch = () => {
      if (searchQuery) {
        //Check if input is an array
        if (!Array.isArray(input)) {
          return onOutputReady([]);
        }

        const filter = input.map((item) => {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const mainArrayCondition = handleFilter(item, searchQuery);

          if (mainArrayCondition) {
            return item;
          } else {
            const filteredValues = item.values.filter((subItem: any) => subItem.value.toLowerCase().includes(searchQuery));

            return {
              ...item,
              values: filteredValues,
            };
          }
        });

        onOutputReady(filter);
      } else {
        onOutputReady(input);
      }
    };

    const simpleSearch = () => {
      if (searchQuery) {
        //Check if input is an array
        if (!Array.isArray(input)) {
          return onOutputReady([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const filter = input.filter((item) => handleFilter(item, searchQuery?.toLowerCase()));
        onOutputReady(filter);
      } else {
        onOutputReady(input);
      }
    };

    if (input[0]?.headTitle) {
      multiArraySearch();
    } else {
      simpleSearch();
    }
  }, [searchQuery, input, onOutputReady]);

  // const onOutputReady = (data: any[]) => {
  //   if (!Array.isArray(input)) {
  //     return output([]);
  //   }
  //   output(data);
  // };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchQuery(text);
  };
  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml}>
      <SearchSkeleton
        {...props}
        placeholder={placeholder}
        onBlur={onBlur}
        disabled={disabled}
        onChange={onChangeHandler}
        variant={variant}
        width={width}
        id={id}
        name="search"
        input={input}
        output={output}
        value={searchQuery}
      />
      {showIcon && (
        <SearchIcon>
          <BiSearchAlt />
        </SearchIcon>
      )}
    </DivSkeleton>
  );
};

const SearchSkeleton = styled.input<Partial<SearchProps>>`
  ${searchStyles.base};
  ${(props) => props.variant && searchStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
  ${(props) => (props.height ? `height: ${props.height}rem` : '')};
  ${(touched) => (touched ? `color: var(--color-dark)` : '')}
`;

const DivSkeleton = styled.div<Partial<SearchProps>>`
  ${searchStyles.divBase};
  ${(props) => props.variant && searchStyles[props.variant]};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : '')};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
`;

const SearchIcon = styled.div`
  ${searchStyles.iconBase};
`;

export default MimaSearch;
