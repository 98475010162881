import React, { useState } from 'react';
import styled from 'styled-components';
import { searchStyles } from './styles';
import { BiSearchAlt } from 'react-icons/bi';
import { useAsyncDebounce } from '@/hooks';

interface TableSearchProps {
  placeholder?: string;
  id?: string;
  value?: any;
  name?: string;
  onBlur?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  variant?: 'regular' | 'wide';
  width?: any;
  icon?: React.ReactElement;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  error?: any;
  touched?: any;
  input?: any[]; // Adjust the type accordingly
  output?: () => void;
  loading?: boolean;
  handleFilter?: () => void;
  showIcon?: boolean;
  setSearchQuery: (query: string) => void;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}

const TableSearch: React.FC<TableSearchProps> = ({
  filter,
  setFilter,
  placeholder,
  onBlur,
  disabled = false,
  variant = 'regular',
  width,
  id,
  name,
  mt,
  mr,
  mb,
  ml,
  loading = false,
  showIcon = true,
  setSearchQuery,
  ...props
}) => {
  const [value, setValue] = useState(filter);

  const debouncedOnChange = useAsyncDebounce((value: string) => {
    setFilter(value);
    setSearchQuery(value);
  }, 1000);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    await debouncedOnChange(value);
  };

  return (
    <DivSkeleton>
      <SearchSkeleton
        {...props}
        placeholder={placeholder}
        onBlur={onBlur}
        disabled={disabled}
        variant={variant}
        width={width}
        id={id}
        name={name}
        value={value || ''}
        // onChange={(e) => {
        //   setValue(e.target.value);
        //   onChange(e.target.value);
        // }}
        onChange={onChange}
      />
      {showIcon && (
        <SearchIcon>
          <BiSearchAlt />
        </SearchIcon>
      )}
    </DivSkeleton>
  );
};

const SearchSkeleton = styled.input<Partial<TableSearchProps>>`
  ${searchStyles.base};
  ${(props) => props.variant && searchStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
  ${(props) => (props.height ? `height: ${props.height}rem` : '')};
  ${(touched) => (touched ? `color: var(--color-dark)` : '')}
`;

const DivSkeleton = styled.div<Partial<TableSearchProps>>`
  ${searchStyles.divBase};
  ${(props) => props.variant && searchStyles[props.variant]};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : '')};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
`;

const SearchIcon = styled.div`
  ${searchStyles.iconBase};
`;

export default TableSearch;
