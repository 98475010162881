import React, { ReactNode } from 'react';
import { Header, Footer } from './_PageLayoutComponents'; // Adjust the import paths as needed

interface LayoutProps {
    children: ReactNode;
}


const PageLayout2: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <Header variant='header2' />
            <main>{children}</main>
            <Footer />
        </>
    );
};

export default PageLayout2;

