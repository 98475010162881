const Colors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  primary: {
    50: '#1F68D6',
    100: '#3B7EE3',
    200: '#CCEDFF',
    300: '#003C97',
    400: '#003899',
    500: '#C1DAFF',
    600: '#97C1FF',
    700: '#CCEDFF',
    800: '#A8E1AE',
    900: '#A1C4F9',
    1000: '#F5F5F5',
  },
  grey: {
    50: '#B3B3C5',
    100: '#9898AB',
    200: '#E0E9F3',
    300: '#D1D1D1',
    400: '#EBEBF5',
    500: '#FAFAFC',
    600: '#F4F4FC',
    700: '#4A4A68',
    800: '#1A1A42',
    900: '#696365',
    1000: '#60607A',
    1100: '#2A2828',
    1200: '#F3F3FF',
    1300: '#C0C0C0',
  },
  red: {
    50: '#E12F2F',
    100: '#FFE8E8',
    200: '#C43C2D',
    300: '#AF2415',
    900: '#9F0000',
  },
  green: {
    50: '#66B032',
    100: '#569755',
    200: '#92E391',
    300: '#D3EACE',
    400: '#E4FFEC',
  },
  yellow: {
    50: '#E7D788',
    100: '#FFC247',
    200: '#8F4F00',
    300: '#FFF5D7',
    900: '#7E6E1F',
  },
  blue: {
    50: '#EBF2FF',
    100: '#D4E5FF',
    200: '#E7F3FE',
  },
};
// 'color-light': '#F4F9FF',
// 'color-light-2': '#E0E0E0',
// 'color-admin': '#112855',
// 'color-admin-2': '#071530',
// 'color-admin-3': '#020710',
// 'color-light-brown': '#F4F5F6',
// 'color-sell-main': '#001432',
// 'color-sell-2': '#5F5F61',
const Theme = {
  Colors,
};
export default Theme;
