import { Data, IFaqDTO } from '@/interfaces';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class SupportStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'SupportStore', properties: ['faqs', 'contactDetails', 'contactUs'] });
  }
  faqs: IFaqDTO[] = [];
  contactDetails: Data = {};
  contactUs: Data = {};

  setFaqs = async (faqs: IFaqDTO[]) => {
    runInAction(async () => {
      this.faqs = faqs;
    });
  };

  setContactDetails = async (contact: Data) => {
    runInAction(async () => {
      this.contactDetails = contact;
    });
  };
  setContactUs = async (state: Data) => {
    runInAction(async () => {
      this.contactUs = state;
    });
  };
}
