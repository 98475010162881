/**
 * MobX store classes instantiation.
 * This is where all MobX stores are instantiated
 * and exported for use within the Provider.
 */
import { configurePersistable } from 'mobx-persist-store';
import React from 'react';
import { configure } from 'mobx';
import UserStore from './UserStore';
import BusinessStore from './BusinessStore';
import SupportStore from './SupportStore';
import PartnerStore from './PartnerStore';
import SubscriptionStore from './SubscriptionStore';

/**
 * Configuration to ensure all state changes occur via MobX actions
 */

configure({ enforceActions: 'observed' });
configurePersistable(
  {
    storage: undefined,
    expireIn: 86400000,
    removeOnExpiration: true,
    stringify: true,
    debugMode: false,
  },
  { delay: 200, fireImmediately: false },
);

export const userStore = new UserStore();
export const businessStore = new BusinessStore();
export const supportStore = new SupportStore();
export const partnerStore = new PartnerStore();
export const subscriptionStore = new SubscriptionStore();

export const storesContext = React.createContext({
  userStore,
  businessStore,
  supportStore,
  subscriptionStore
});

export const useStores = () => React.useContext(storesContext);
