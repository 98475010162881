import { css, FlattenSimpleInterpolation } from 'styled-components';

export type SearchStyleType = {
  base: FlattenSimpleInterpolation;
  regular: FlattenSimpleInterpolation;
  dropdown: FlattenSimpleInterpolation;
  divBase: FlattenSimpleInterpolation;
  iconBase: FlattenSimpleInterpolation;
  wide: FlattenSimpleInterpolation;
};

export const searchStyle: SearchStyleType = {
  base: css`
    font-size: 1.8rem;
    font-family: inherit;
    color: var(--color-dark);
    padding-left: 2rem;
    height: 6rem;
    border-radius: 1rem;
    background-color: var(--color-white);
    border: 2px solid var(--color-primary);
    display: block;
    box-sizing: border-box;
  `,
  regular: css`
    width: 38rem;
    margin-bottom: 0.5rem;
  `,
  wide: css`
    width: 60rem;

    @media only screen and (max-width: 31.25em) {
      width: 38rem;
    }
  `,
  dropdown: css`
    width: 100%;
  `,
  divBase: css`
    position: relative;
  `,
  iconBase: css`
    width: 6rem;
    height: 6rem;
    color: var(--color-primary);
    font-size: 3rem;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
};
