import DropIcon from '@/assets/svg/arrowDown.svg';
import { css, FlattenSimpleInterpolation } from 'styled-components';

export type ButtonStyleType = {
  base: FlattenSimpleInterpolation;
  contained: FlattenSimpleInterpolation;
  outlined: FlattenSimpleInterpolation;
  colorable: FlattenSimpleInterpolation;
  regular: FlattenSimpleInterpolation;
  fullWidth: FlattenSimpleInterpolation;
  outlinedWhite: FlattenSimpleInterpolation;
};

export const buttonStyles: ButtonStyleType = {
  base: css`
    padding: 1.2rem 2.4rem;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    line-height: 2.4rem;
    font-weight: 500;
    border-radius: 0.4rem;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    &:disabled {
      background-color: var(--color-grey-2);
    }
    &:hover {
      background-color: var(--color-grey-2);
      transition: background-color 200ms ease-in-out;
    }
    &:active {
      transform: scale(0.99);
    }
  `,
  contained: css`
    background-color: var(--color-dark);
    color: var(--color-white);
    width: fit-content;
    padding: 1.3rem 1.6rem;
    &:hover {
      background-color: var(--color-dark-2);
      transition: background-color 200ms ease-in-out;
    }
  `,
  regular: css`
    background-color: var(--color-dark);
    color: var(--color-white);
    width: 38rem;
    &:disabled {
      background-color: var(--color-grey-2);
    }
    &:hover {
      background-color: var(--color-dark-2);
      transition: background-color 200ms ease-in-out;
    }
    &:active {
      transform: scale(0.99);
    }
  `,
  fullWidth: css`
    background-color: var(--color-dark);
    color: var(--color-white);
    width: 100%;
    transition: background-color 200ms ease-in-out;
    &:hover {
      background-color: var(--color-dark-2);
      transition: background-color 200ms ease-in-out;
    }
    &:active {
      transform: scale(0.99);
    }
  `,
  outlined: css`
    border: 1px solid var(--color-dark);
    color: var(--color-dark);
    &:hover {
      border: 2px solid var(--color-dark);
      color: var(--color-dark);
    }
  `,
  outlinedWhite: css`
    border: 1px solid var(--color-white);
    color: var(--color-white);
    &:hover {
      border: 2px solid var(--color-primary-2);
      color: var(--color-white);
    }
  `,
  colorable: css`
    background-color: var(--color-dark-2);
    color: var(--color-white);
    width: 100%;
    margin-top: 2rem;
    &:hover {
      filter: brightness(0.8);
    }
  `,
};
