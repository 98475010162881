import { css, FlattenSimpleInterpolation } from 'styled-components';

// Define type for the Button Styles
export type ButtonStyleType = {
  baseButton: FlattenSimpleInterpolation;
  title: FlattenSimpleInterpolation;
  icon: FlattenSimpleInterpolation;
};

// Base button styles
export const buttonStyles: ButtonStyleType = {
  baseButton: css`
    display: flex;
    padding: 0.3rem 0.4rem;
    align-items: center;
    gap: 0.6rem;
    border-radius: 0.4rem;
    font-family: var(--primary-font);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
    border: 2px solid;

    &:active {
      transform: scale(0.98);
    }
  `,

  // Abstracted title styles
  title: css`
    padding: 1.2rem 1.6rem;
    border-radius: 0.4rem;
    color: var(--color-white);
    font-size: 1.6rem;
    transition:
      background-color 0.5s ease,
      color 0.5s ease;
    white-space: nowrap;
  `,
  // Abstracted icon styles
  icon: css`
    width: 4rem;
    padding: 0rem 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 400ms ease;
  `,
};
