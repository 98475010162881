/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import { searchStyle } from './styles';
import { BiSearchAlt } from 'react-icons/bi';
import { observer } from 'mobx-react';

interface SearchbarProps {
  placeholder?: string;
  id?: string;
  value?: any;
  name?: string;
  onBlur?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  variant?: 'regular' | 'wide' | 'dropdown';
  width?: any;
  icon?: React.ReactElement;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  error?: any;
  touched?: any;
  input: any[]; // Adjust the type accordingly
  output: React.Dispatch<React.SetStateAction<any>>;
  loading?: boolean;
  handleFilter: (item: any, search: any) => void;
  showIcon?: boolean;
}

const defaultProps: Partial<SearchbarProps> = {
  placeholder: '',
  id: '',
  value: '',
  name: '',
  onBlur: () => {},
  onChange: () => {},
  disabled: false,
  variant: 'regular',
  width: '',
  loading: false,
  input: [],
  output: (result) => {},
  handleFilter: (item, search) => {},
  showIcon: true,
};

const Searchbar: React.FC<SearchbarProps> = ({
  placeholder,
  onBlur,
  onChange,
  value,
  disabled,
  variant,
  width,
  id,
  name,
  mt,
  mr,
  mb,
  ml,
  input,
  output,
  loading,
  handleFilter,
  showIcon,
  ...props
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (searchQuery) {
      //Check if input is an array
      if (!Array.isArray(input)) {
        return onOutputReady([]);
      }

      const filter = input.filter((item) => handleFilter(item, searchQuery?.toLowerCase()));
      onOutputReady(filter);
    } else {
      onOutputReady(input);
    }
  }, [searchQuery, input]);

  const onOutputReady = (data: any) => {
    if (!Array.isArray(input)) {
      return output([]);
    }

    output(data);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchQuery(text);
  };

  return (
    <DivSkeleton>
      <SearchSkeleton
        {...props}
        placeholder={placeholder}
        onBlur={onBlur}
        disabled={disabled}
        onChange={onChangeHandler}
        variant={variant}
        width={width}
        id={id}
        name={name}
        input={input}
        output={output}
        value={searchQuery}
      />
      {showIcon && (
        <SearchIcon>
          <BiSearchAlt />
        </SearchIcon>
      )}
    </DivSkeleton>
  );
};

const SearchSkeleton = styled.input<Partial<SearchbarProps>>`
  ${searchStyle.base};
  ${(props) => props.variant && searchStyle[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
  ${(props) => (props.height ? `height: ${props.height}rem` : '')};
  ${(touched) => (touched ? `color: var(--color-dark)` : '')}
`;

const DivSkeleton = styled.div<Partial<SearchbarProps>>`
  ${searchStyle.divBase};
  ${(props) => props.variant && searchStyle[props.variant]};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : '')};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
`;

const SearchIcon = styled.div`
  ${searchStyle.iconBase};
`;

Searchbar.defaultProps = defaultProps;

export default observer(Searchbar);
