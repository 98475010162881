import styled from 'styled-components';

import Theme from '../Theme';

const { Colors } = Theme;

export const ErrorMessage = styled.p`
  font-family: inherit;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 8px;
  color: ${Colors.red[50]};
`;
