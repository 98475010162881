import { Data, ISubscriptionDTO } from '@/interfaces';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class BusinessStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'BusinessStore',
      properties: [
        'business',
        'OnboardingStageOne',
        'bId',
        'businesses',
        'subscriptionPlans',
        'expiredSubscription',
        'businessMobile',
        'defaultCurrency',
      ],
    });
  }

  // Store state
  business: Data = {};
  businesses: Data[] = [];
  bId: string = '';
  OnboardingStageOne: Data = {};
  subscriptionPlans: ISubscriptionDTO[] = [];
  expiredSubscription: boolean = false;
  businessMobile: string = '';
  defaultCurrency: string = '';

  // Method to set store properties

  setBusinessOnboardingStageOne = async (state: Data) => {
    runInAction(async () => {
      this.OnboardingStageOne = state;
    });
  };

  setBusiness = async (business: Data) => {
    runInAction(async () => {
      this.business = business;
    });
  };
  setBusinesses = async (businesses: Data[]) => {
    runInAction(async () => {
      this.businesses = businesses;
    });
  };
  setBId = async (business: string) => {
    runInAction(async () => {
      this.bId = business;
    });
  };
  setSubscriptionPlans = async (state: ISubscriptionDTO[]) => {
    runInAction(async () => {
      this.subscriptionPlans = state;
    });
  };

  setExpiredSubscription = async (state: boolean) => {
    runInAction(async () => {
      this.expiredSubscription = state;
    });
  };

  setBusinessMobile = async (state: string) => {
    runInAction(async () => {
      this.businessMobile = state;
    });
  };

  setDefaultCurrency = async (state: string) => {
    runInAction(async () => {
      this.defaultCurrency = state;
    });
  };

  // Reset method to clear the store
  reset = () => {
    this.business = {};
    this.OnboardingStageOne = {};
    this.bId = '';
    this.businesses = [];
    this.subscriptionPlans = [];
    this.expiredSubscription = false;
    this.businessMobile = '';
    this.defaultCurrency = '';
  };
}
