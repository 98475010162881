import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import styles from '@/assets/styles/components/MimaRadioButton.module.scss';

interface CheckBoxProps {
  name: string;
  labelTitle: string;
  control: any;
  defaultValue?: boolean;
  onClick?: () => void;
}

const MimaCheckBox: React.FC<CheckBoxProps> = ({ name, labelTitle, control, defaultValue = false, onClick }) => {
  const {
    field: { onChange, onBlur, value: checkBoxValue },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const runOnClick = () => {
      onClick ? onClick() : null;
    };

    onChange(e);
    runOnClick();
  };

  return (
    <label htmlFor={name} className={styles.cb}>
      <input
        type="checkbox"
        name={name}
        // value={value}
        id={name}
        checked={checkBoxValue}
        onChange={changeHandler}
        onBlur={onBlur}
        className={styles.cb__input}
      />
      <span className={styles.cb__check}></span>
      <p>{labelTitle}</p>
    </label>
  );
};

export default MimaCheckBox;
