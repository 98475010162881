import styled from 'styled-components';
import { textStyles } from './styles';
import React from 'react';

interface MimaTextProps {
  children: React.ReactNode;
  color?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' |'h4Bold' | 'subtitle' | 'subtitleBold' | 'body' | 'bodyBold' | 'small' | 'smallBold' | 'xsmall' ;
  className?: any;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  width?: number;
  fontStyle?: string;
  fontWeight?: string | number;
  style?: React.CSSProperties;
  align?: string;
  labelTitle?: string;
  labelColor?: string;
}

const MimaText: React.FC<MimaTextProps> = ({ children, labelTitle, labelColor, variant = 'body', color = 'inherit', ...props }) => {
  return (
    <DivSkeleton {...props}>
      {labelTitle ? <LabelSkeleton labelColor={labelColor}>{labelTitle}</LabelSkeleton> : null}
      <TextSkeleton variant={variant} color={color} {...props}>
        {children}{' '}
      </TextSkeleton>
    </DivSkeleton>
  );
};

const TextSkeleton = styled.p<Partial<MimaTextProps>>`
  ${textStyles.base};
  ${(props) => props.variant && textStyles[props.variant]};
  ${(props) => (props.fontStyle ? `font-style: ${props.fontStyle}` : '')};
  ${(props) => (props.color ? `color: ${props.color}` : '')};
  ${(props) => (props.align ? `text-align: ${props.align}` : '')};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : '')}; 
`;

const DivSkeleton = styled.div<Partial<MimaTextProps>>`
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : '')};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
`;

const LabelSkeleton = styled.label<Partial<MimaTextProps>>`
  ${textStyles.label};
  ${(props) => (props.labelColor ? `color: ${props.labelColor}` : '')};
`;

export default MimaText;
