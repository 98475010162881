import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { IoCaretForward, IoCaretBack } from 'react-icons/io5';
import { AiFillBackward, AiFillForward } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import TableSearch from './TableSearch';
import styles from '../../assets/styles/components/MimaTable.module.scss';
import PageLoader from '../PageLoader';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  ColumnDef,
  getSortedRowModel,
  Table,
  getFilteredRowModel,
  SortingState,
} from '@tanstack/react-table';
import { MimaText } from '..';
import { IDefaultObject } from '@/interfaces';
import { EmptyStateImage } from '@/assets/img/ImgList';
import Image from 'next/image';

interface TableProps {
  // tableData: Record<string, any>[];
  tableData: IDefaultObject[] | undefined;
  tableColumns: ColumnDef<any>[];
  searchPlaceholder?: string;
  searchVariant?: 'regular' | 'wide';
  totalItems?: number;
  onLimitChange?: (limit: number) => void;
  isLoading?: boolean;
  isFetching?: boolean;
  limit?: number;
  currentPage?: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  emptyMessage?: string;
}

type PageButton = number | '...';

const defaultTableData = [
  {
    id: 1,
    first_name: 'Millicent',
    last_name: 'Whatham',
    email: 'mwhatham0@comsenz.com',
    gender: 'Female',
    university: 'Samarkand State University',
  },
  {
    id: 2,
    first_name: 'Siward',
    last_name: 'Amberger',
    email: 'samberger1@behance.net',
    gender: 'Male',
    university: 'Institute of Industrial Electronics Engineering',
  },
  {
    id: 3,
    first_name: 'Sheree',
    last_name: 'Madeley',
    email: 'smadeley2@google.com',
    gender: 'Female',
    university: 'Kateb Institute of Higher Education',
  },
];

const defaultTableColumns = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'First Name',
    accessorKey: 'first_name',
  },
  {
    header: 'Last Name',
    accessorKey: 'last_name',
  },
  {
    header: 'Email',
    accessorKey: 'email',
  },
  {
    header: 'Gender',
    accessorKey: 'gender',
  },
  {
    header: 'University',
    accessorKey: 'university',
  },
];

const MimaTable: React.FC<TableProps> = ({
  tableData = defaultTableData,
  tableColumns = defaultTableColumns,
  searchPlaceholder = 'Search...',
  searchVariant = 'wide',
  totalItems,
  onLimitChange = (limit: number) => {},
  isLoading = false,
  isFetching = false,
  limit = 50,
  currentPage = 0,
  setCurrentPage,
  setSearchQuery,
  emptyMessage = 'No data available',
  ...props
}) => {
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const usedLimit = useMemo(() => limit, [limit]);

  // const [currentPage, setCurrentPage] = useState(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filtering, setFiltering] = useState('');

  const table: Table<any> = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,

    state: {
      sorting,
      globalFilter: filtering,
      pagination: {
        pageSize: usedLimit,
        pageIndex: 0,
      },
    },
  });

  const maxDisplayedPages = 3; // Maximum number of page buttons to display

  const pageSize: number = table.options.state.pagination?.pageSize || 10;
  const pageCount = table.getPageCount();
  const pageIndex = table.options.state.pagination?.pageIndex || 0;

  const totalPages = totalItems ? Math.ceil(totalItems / pageSize) : pageCount;

  const pageButtons = useMemo<PageButton[]>(() => {
    let pageButtons: PageButton[] = [];
    if (totalPages <= maxDisplayedPages) {
      pageButtons = Array.from({ length: totalPages }, (_, index) => index);
      return pageButtons;
    } else {
      const currentPage: number = pageIndex;
      const startPage: number = Math.max(0, currentPage - Math.floor(maxDisplayedPages / 2));
      const endPage: number = Math.min(totalPages - 1, startPage + maxDisplayedPages - 1);

      if (startPage > 0) {
        pageButtons.push('...');
      }

      pageButtons = pageButtons.concat(Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index));

      if (endPage < totalPages - 1) {
        pageButtons.push('...');
      }
      return pageButtons;
    }
  }, [totalPages, maxDisplayedPages, pageIndex]);

  const sortIcon = (header: any) => {
    const isSorted = header.column.getIsSorted();
    return <span>{isSorted === 'asc' ? <FaArrowUp /> : isSorted === 'desc' ? <FaArrowDown /> : null} </span>;
  };

  return (
    <>
      <div className="searchInMiddle">
        <TableSearch
          filter={filtering}
          setFilter={setFiltering}
          placeholder={searchPlaceholder}
          variant={searchVariant}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <div className="whiteTable">
        {isLoading ? (
          <PageLoader loading={isLoading} title="while we fetch your data" />
        ) : (
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {sortIcon(header)}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {tableData?.length <= 0 ? (
          <div className="flex flex-col w-full items-center mt-8">
            <Image src={EmptyStateImage} alt="No products" style={{ width: '25rem', height: 'auto' }} />
            <MimaText variant="subtitleBold" mt={2} align="center">
              {emptyMessage}
            </MimaText>
          </div>
        ) : (
          ''
        )}
      </div>

      {isFetching && <PageLoader loading={isFetching} type="bar" />}

      <div className={styles.pagination__group}>
        <div className={styles.pagination}>
          <select
            value={table.options.state.pagination?.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
              onLimitChange(Number(e.target.value));
            }}
          >
            {[50, 100, 200].map((pageSize: number) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <strong>
            {currentPage + 1} of {totalItems ? totalPages : table.getPageCount()}
          </strong>
          {/* <strong>
            {pageIndex + 1} of {table.getPageCount()}
          </strong> */}
        </div>
        <div className={styles.pagination}>
          <button
            onClick={() => {
              // table.setPageIndex(0);
              setCurrentPage(0);
            }}
            // disabled={!table.getCanPreviousPage()}
            disabled={currentPage === 0}
            className={styles.numberedPage}
          >
            <AiFillBackward style={{ fontSize: '2rem' }} />
          </button>
          <button
            onClick={() => {
              // table.previousPage();
              setCurrentPage(currentPage - 1);
            }}
            // disabled={!table.getCanPreviousPage()}
            disabled={currentPage === 0}
            className={styles.numberedPage}
          >
            <IoCaretBack style={{ fontSize: '2rem' }} />
          </button>
          {pageButtons.map((page, index) =>
            page === '...' ? (
              <BsThreeDots key={index} style={{ fontSize: '2rem' }} />
            ) : (
              <button
                key={index}
                onClick={() => {
                  //   gotoPage(page);
                  setCurrentPage(page);
                  // table.setPageIndex(page);
                }}
                // className={`${styles.numberedPage} ${pageIndex === page ? styles.numberPageActive : ''}`}
                className={`${styles.numberedPage} ${currentPage === page ? styles.numberPageActive : ''}`}
              >
                {page + 1}
              </button>
            ),
          )}

          <button
            onClick={() => {
              // table.nextPage();
              setCurrentPage(currentPage + 1);
            }}
            // disabled={!table.getCanNextPage()};
            disabled={currentPage === totalPages - 1}
            className={styles.numberedPage}
          >
            <IoCaretForward style={{ fontSize: '2rem' }} />
          </button>
          <button
            onClick={() => {
              // table.setPageIndex(table.getPageCount() - 1);
              setCurrentPage(totalPages - 1);
            }}
            // disabled={!table.getCanNextPage()}
            disabled={currentPage === totalPages - 1}
            className={styles.numberedPage}
          >
            <AiFillForward style={{ fontSize: '2rem' }} />
          </button>
        </div>
      </div>
    </>
  );
};

MimaTable.displayName = 'MimaTable';

export default MimaTable;
