import FashWhiteLogo from './logos/Fash creative white logo.png';
import FashMainLogo from './logos/Fash creative.png';
import SliderImg1 from './pictures/slide-img-1.png';
import SliderImg3 from './pictures/slide-img-5.png';
import SliderImg5 from './pictures/slide-img-7.png';
import SliderImg6 from './pictures/slide-img-8.png';
import AppPhone from './pictures/fash creatives app phone.png';
import CreateStyleImg from './home/iphone half.png';
import HowToImage from './home/How To Image.png';
import EmptyStateImage from './pictures/Empty Table.png';
import Rectangle from './Rectangle 451.png';
import BouncySquareLoader from './Loader/bouncing-squares.svg'
import ladyOnPhone from'./pictures/Lady on phone.png'
import * as partnershipImages from './partnerships'
export {
  FashWhiteLogo,
  FashMainLogo,
  SliderImg1,
  SliderImg3,
  SliderImg5,
  SliderImg6,
  AppPhone,
  CreateStyleImg,
  EmptyStateImage,
  HowToImage,
  Rectangle,
  BouncySquareLoader,
  ladyOnPhone,
  partnershipImages
};

const imageList = {
  createStyleImg: 'https://res.cloudinary.com/mima-business/image/upload/v1712969163/fash%20creatives%20web/iphone_half_ubikga.png',
  packOrder: 'https://res.cloudinary.com/mima-business/image/upload/v1713028245/fash%20creatives%20web/pexels-ron-lach-9594415_antrk8.jpg',
  orderItem: 'https://res.cloudinary.com/mima-business/image/upload/v1713028244/fash%20creatives%20web/close-up-man-shopping-with-laptop_k7m21c.jpg',
  tailor: 'https://res.cloudinary.com/mima-business/image/upload/v1713028242/fash%20creatives%20web/pexels-pavel-danilyuk-6461396_ox5qwr.jpg',
  appMenu: 'https://res.cloudinary.com/mima-business/image/upload/v1713028240/fash%20creatives%20web/iphone_half_2_uc5a5p.png',
  paybills: 'https://res.cloudinary.com/mima-business/image/upload/v1713029608/fash%20creatives%20web/iphone_half3_o8a1bb.png',
  globeWorld: 'https://res.cloudinary.com/mima-business/image/upload/v1713059311/fash%20creatives%20web/globe_world_xeivpx.png',
};

export default imageList;
