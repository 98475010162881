import { IMarketer, IMarketerWithAccount, IPartnerAnalysis, IPartnerBankList } from '@/interfaces';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class PartnerStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'PartnerStore',
      properties: ['token', 'user', 'marketerId', 'isActivated', 'banks', 'analysis', 'marketer', 'marketerWithBank'],
    });
  }
  token = '';
  user = {};
  marketerId = '';
  isActivated = false;
  banks: IPartnerBankList[] | [] = [];
  analysis: IPartnerAnalysis | {} = {};
  marketer: IMarketer | {} = {};
  marketerWithBank: IMarketerWithAccount | {} = {};

  reset = () => {
    this.token = '';
    this.user = {};
    this.marketerId = '';
    this.isActivated = false;
    this.analysis = [];
    this.marketer = {};
    this.marketerWithBank = {};
  };

  setToken = async (state: any) => {
    runInAction(() => {
      this.token = state;
    });
  };

  setUser = async (state: any) => {
    runInAction(async () => {
      this.user = state;
    });
  };

  setMarketerId = async (state: string) => {
    runInAction(() => {
      this.marketerId = state;
    });
  };

  setActivated = async (state: any) => {
    runInAction(() => {
      this.isActivated = state;
    });
  };

  setBanks = async (state: IPartnerBankList[]) => {
    runInAction(async () => {
      this.banks = state;
    });
  };

  setAnalysis = async (state: IPartnerAnalysis) => {
    runInAction(async () => {
      this.analysis = state;
    });
  };
  setMarketer = async (state: IMarketer) => {
    runInAction(async () => {
      this.marketer = state;
    });
  };

  setMarketerWithBank = async (state: IMarketerWithAccount) => {
    runInAction(async () => {
      this.marketerWithBank = state;
    });
  };
}
