import { Data, IUserDTO } from '@/interfaces';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { businessStore, partnerStore } from '.';

export default class UserStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UserStore',
      properties: ['isLoggedIn', 'token', 'user', 'otpType', 'userId'],
    });
  }

  isLoggedIn = false;
  token = '';
  user: IUserDTO = {};
  otpType = '';
  userId = '';

  reset = () => {
    this.isLoggedIn = false;
    this.token = '';
    this.user = {};
    this.otpType = '';
    this.userId = '';
  };

  setLoggedIn = async (state: boolean) => {
    if (state === false) {
      this.reset();
      partnerStore.reset();
      businessStore.reset();
    }
    runInAction(() => {
      this.isLoggedIn = state;
    });
  };

  setToken = async (state: string) => {
    runInAction(() => {
      this.token = state;
    });
  };

  setUser = async (user: IUserDTO) => {
    runInAction(async () => {
      this.user = user;
    });
  };

  setOtpType = async (state: string) => {
    runInAction(() => {
      this.otpType = state;
    });
  };

  setUserId = async (state: string) => {
    runInAction(() => {
      this.userId = state;
    });
  };
}
