import React, { FC, useState, useEffect, useMemo } from 'react';
import { FashMainLogo, FashWhiteLogo } from '@/assets/img/ImgList';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';
import { MimaButton } from '@/components';
import Link from 'next/link';
import Image from 'next/image';
import styles from '@/assets/styles/components/Layout.module.scss';
import { HeaderNavItems } from './Navitems';
import { useSwitchNav } from './SwitchNav';

interface IHeaderProps {
  variant?: 'header1' | 'header2';

}



const Header: FC<IHeaderProps> = ({ variant = 'header1' }) => {
  const [menu, setMenu] = useState(false);
  const [isTop, setIsTop] = useState(true);

  const { switchToLanding, switchToSignup, switchToLogin } = useSwitchNav();
  const navItems = useMemo(() => HeaderNavItems, []);


  const showMenu = () => setMenu(!menu);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        setIsTop(true); // User is at the top
      } else {
        setIsTop(false); // User has scrolled away from the top
      }
    };
    checkScrollPosition();

    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, []);


  return (
    <header className={`${variant === 'header1' ? styles.header1 : styles.header2} ${isTop ? (menu ? styles.visible : styles.top) : styles.visible}`}>

      <nav
        className={styles.nav}
      >
        <Image
          src={variant === 'header1' ? FashWhiteLogo : FashMainLogo}
          className="w-72"
          alt="Fash Creative Logo"
          onClick={switchToLanding}
        />

        <ul className={styles.navMenu}>
          {navItems.map((navItem, i) => (
            <li key={i}>
              <Link href={`${navItem.link}${navItem.id}`}>{navItem.title}</Link>
            </li>
          ))}
        </ul>

        <div className={styles.btnSet}>
          <MimaButton title="Log in" variant={variant === 'header1' && isTop ? 'regular' : 'outlined'} buttonColor={variant === 'header1' && isTop ? 'var(--color-white)' : ''} titleColor={isTop ? 'var(--color-dark)' : ''} width={19.5} onClick={switchToLogin} />
          <MimaButton title="Sign up" onClick={switchToSignup} width={19.5} />
        </div>

        <div
          onClick={showMenu}
          className={styles.hamburger}
          aria-controls="mobile-menu"
          aria-expanded={menu}
          aria-label="Toggle navigation"
        >
          {menu ? <IoMdClose /> : <IoMdMenu />}
        </div>

      </nav>


      <AnimatePresence>
        {menu && (
          <motion.div
            initial={{ opacity: 0, y: '-100%' }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, y: '-100%', transition: { duration: 0.3 } }}
            className={styles.mobileNav}
          >

            <ul className={styles.mobileNaveMenu}>
              {navItems.map((navItem, i) => (
                <li key={i} onClick={showMenu}>
                  <Link href={`${navItem.link}${navItem.id}`}>{navItem.title}</Link>
                </li>
              ))}
            </ul>


            <div className={styles.btnSet}>
              <MimaButton title="Log in" variant="outlined" onClick={switchToLogin} mb={2} width={32} />
              <MimaButton title="Sign up" variant='fullWidth' onClick={switchToSignup} width={32} />
            </div>

          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
