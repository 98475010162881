import { useRouter } from 'next/navigation';
import constant from '@/utils/constant';

export const useSwitchNav = () => {
  const router = useRouter();
  const BASE_APP_URL = process.env.NEXT_PUBLIC_BASE_APP_URL || ''; // Ensure this variable is set in the environment

  const switchToLanding = () => {
    router.push(constant.Routes.Home); // Navigates to the home route
  };

  const switchToSignup = () => {
    router.push(`${BASE_APP_URL}${constant.Routes.SignUp}`); // Concatenates the base URL and signup route
  };

  const switchToLogin = () => {
    router.push(`${BASE_APP_URL}${constant.Routes.Login}`); // Concatenates the base URL and login route
  };

  return {
    switchToLanding,
    switchToSignup,
    switchToLogin,
  };
};
