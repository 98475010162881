import { css, FlattenSimpleInterpolation } from 'styled-components';

export type TextStyleType = {
  base: FlattenSimpleInterpolation;
  h1: FlattenSimpleInterpolation;
  h2: FlattenSimpleInterpolation;
  h3: FlattenSimpleInterpolation;
  h4: FlattenSimpleInterpolation;
  h4Bold: FlattenSimpleInterpolation;
  subtitle: FlattenSimpleInterpolation;
  subtitleBold: FlattenSimpleInterpolation;
  body: FlattenSimpleInterpolation;
  bodyBold: FlattenSimpleInterpolation;
  small: FlattenSimpleInterpolation;
  smallBold: FlattenSimpleInterpolation;
  xsmall: FlattenSimpleInterpolation;
  label: FlattenSimpleInterpolation;
};

export const textStyles: TextStyleType = {
  base: css`
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 400;
    color: var(--color-dark);
  `,
  h1: css`
    font-size: 6.4rem;
    line-height: normal;
    font-weight: 700;

    @media only screen and (max-width: 30em) {
      font-size: 4rem;
    }
  `,
  h2: css`
    font-size: 4.8rem;
    font-weight: 500;
    line-height: normal;
    @media only screen and (max-width: 30em) {
      font-size: 3rem;
    }
  `,
  h3: css`
    font-size: 4rem;
    line-height: 4.9rem;
    font-weight: 700;
  `,
  h4: css`
    font-size: 3.2rem;
    line-height: 3.5rem;
    font-weight: 500;

    @media only screen and (max-width: 56.25em) {
      font-size: 2.4rem;
      line-height: 2.9rem;
    }
  `,
  h4Bold: css`
    font-size: 3.2rem;
    line-height: 3.5rem;
    font-weight: 700;
  `,
  subtitle: css`
    font-size: 2.4rem;
    line-height: 2.9rem;
    font-weight: 400;
  `,
  subtitleBold: css`
    font-size: 2.4rem;
    line-height: 2.9rem;
    font-weight: 700;
  `,
  body: css`
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 400;

  `,

  bodyBold: css`
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 700;
  `,
  small: css`
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 400;
    @media only screen and (max-width: 30em) {
      font-size: 1.5rem;
    }
  `,
  smallBold: css`
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 700;
  `,
  xsmall: css`
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;
  `,
  label: css`
    font-size: var(--default-font-small);
    font-family: inherit;
    color: var(--color-primary-2);
  `,
};
