import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { buttonStyles } from './styles';
import styled from 'styled-components';

interface ButtonProps {
  title: string;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | ((arg: any) => void);
  disabled?: boolean;
  variant?: 'regular' | 'contained' | 'outlined' | 'colorable' | 'fullWidth' | 'outlinedWhite';
  width?: number;
  height?: number;
  icon?: React.ReactNode;
  buttonColor?: string;
  labelTitle?: string;
  titleColor?: string;
}

interface DivProps {
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  width?: number;
  height?: number;
}

const MimaButton: React.FC<ButtonProps & DivProps> = ({
  title,
  loading = false,
  disabled = false,
  icon,
  labelTitle,
  mt,
  mb,
  mr,
  ml,
  width,
  height,
  type = 'button',
  variant = 'regular',
  ...props
}) => {
  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width} height={height}>
      {labelTitle ? <label className="label">{labelTitle}</label> : ''}
      <ButtonSkeleton {...props} disabled={loading || disabled} icon={icon} width={width} height={height} variant={variant}>
        {loading ? (
          <PulseLoader color={'white'} loading={loading} size={15} />
        ) : (
          <>
            {icon} {title}
          </>
        )}
      </ButtonSkeleton>
    </DivSkeleton>
  );
};

const ButtonSkeleton = styled.button<Partial<ButtonProps>>`
  ${buttonStyles.base};
  ${(props) => props.variant && buttonStyles[props.variant]};
  ${(props) => (props.buttonColor ? `background-color: ${props.buttonColor}` : '')};
  ${(props) => (props.titleColor ? `color: ${props.titleColor}` : '')};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
  ${(props) => (props.height ? `height: ${props.height}rem` : '')};
`;

const DivSkeleton = styled.div<DivProps>`
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : '')};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
  ${(props) => (props.height ? `height: ${props.height}rem` : '')};
`;

export default MimaButton;
