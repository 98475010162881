import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultProps: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const MimaToastUtil = {
  error: ({ message = '', props }: { message?: string; props?: ToastOptions }) => {
    toast.error(message, props || defaultProps);
  },
  success: ({ message = '', props }: { message?: string; props?: ToastOptions }) => {
    toast.success(message, props || defaultProps);
  },
  info: ({ message = '', props }: { message?: string; props?: ToastOptions }) => {
    toast.info(message, props || defaultProps);
  },
};
