import { css, FlattenSimpleInterpolation } from 'styled-components';

export type InputStyleType = {
  base: FlattenSimpleInterpolation;
  medium: FlattenSimpleInterpolation;
  error: FlattenSimpleInterpolation;
  pwIcon: FlattenSimpleInterpolation;
  divBase: FlattenSimpleInterpolation;
  form: FlattenSimpleInterpolation;
  textArea: FlattenSimpleInterpolation;
  formError: FlattenSimpleInterpolation;
  textAreaError: FlattenSimpleInterpolation;
  address: FlattenSimpleInterpolation;
  qtyBtn: FlattenSimpleInterpolation;
  numInput: FlattenSimpleInterpolation;
  exchange: FlattenSimpleInterpolation;
};

export const inputStyles: InputStyleType = {
  base: css`
    font-size: 1.8rem;
    font-family: inherit;
    color: var(--color-grey);
    padding-left: 2rem;
    height: 4.8rem;
    margin-top: 0.4rem;
    border-radius: 0.8rem;
    background-color: var(--color-white);
    border: 1px solid var(--color-dark);
    display: block;
    box-sizing: border-box;
    outline: none;

    &:focus,
    &:focus-visible,
    &:focus-within {
      color: var(--color-dark);
      border: 2px solid var(--color-primary);
      box-shadow: inset 2px 2px 3px #ccad33;
    }

    &:disabled {
      background-color: var(--color-table-grey);
    }
  `,
  medium: css`
    width: 38rem;
    margin-bottom: 0.5rem;
  `,
  form: css`
    width: 100%;
    margin-bottom: 0.5rem;
  `,
  address: css`
    width: 38rem;
    min-height: 4.8rem;
    height: fit-content;
    max-height: 50rem;
    padding: 2rem;
    &::-webkit-scrollbar {
      width: 1rem;
      background-color: var(--color-white);
      border-radius: 0 3rem 3rem 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-grey);
      border-radius: 3rem;
    }
  `,
  textArea: css`
    width: 100%;
    min-width: 38rem;
    margin-bottom: 0.5rem;
    min-height: 12rem;
    max-height: 50rem;
    margin-top: 0.5rem;
    padding: 2rem;

    &::-webkit-scrollbar {
      width: 1rem;
      background-color: var(--color-white);
      border-radius: 0 3rem 3rem 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-grey);
      border-radius: 3rem;
    }
  `,
  error: css`
    width: 38rem;
    border: 2px solid var(--color-red);
    outline: none;
    margin-bottom: 0.5rem;
    padding: 2rem;
  `,

  formError: css`
    width: 100%;
    border: 2px solid var(--color-red);
    outline: none;
    margin-bottom: 0.5rem;
  `,

  textAreaError: css`
    padding: 2rem;
    width: 100%;
    min-height: 12rem;
    border: 2px solid var(--color-red);
    outline: none;
    margin-bottom: 0.5rem;
  `,
  pwIcon: css`
    position: relative;

    & > :nth-child(2) {
      position: absolute;
      bottom: 1rem;
      right: 0;
    }
  `,
  qtyBtn: css`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 0.5rem;

    button {
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      border: 1px solid var(--color-dark);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      cursor: pointer;

      &:hover {
        background-color: var(--color-dark-2);
        color: var(--color-white);
      }

      &:disabled {
        background-color: var(--color-grey);
        color: var(--color-white);
      }
    }
  `,
  numInput: css`
    background-color: transparent;
    width: 10rem;
    padding-left: 2rem;
    font-weight: 600;
    font-size: 1.8rem;
    font-family: inherit;
    color: var(--color-grey);
    height: 4.8rem;
    border: none;
    outline: none

    &:focus,
    &:focus-visible,
    &:focus-within {
      color: var(--color-dark);
      border: none;
      outline: none;
    }
  `,
  divBase: css`
    width: 100%;
  `,
  exchange: css`
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--color-dark);
    width: 15rem;
    padding-left: 0;
    height: auto;

    &:focus,
    &:focus-visible,
    &:focus-within {
      border: none;
      border-radius: 0;
      color: var(--color-dark);
      border-bottom: 1px solid var(--color-primary);
    }
  `,
};
