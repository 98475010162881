import { css, FlattenSimpleInterpolation } from 'styled-components';
import arrowDown from '@/assets/svg/arrowDown.svg';

export type DropDownStyleType = {
  base: FlattenSimpleInterpolation;
  medium: FlattenSimpleInterpolation;
  error: FlattenSimpleInterpolation;
  bodyBase: FlattenSimpleInterpolation;
  divBase: FlattenSimpleInterpolation;
  iconBase: FlattenSimpleInterpolation;
  form: FlattenSimpleInterpolation;
  fullWidth: FlattenSimpleInterpolation;
  multiDivBase: FlattenSimpleInterpolation;
  multiBodyBase: FlattenSimpleInterpolation;
  bodyBase2: FlattenSimpleInterpolation;
  fullWidthError: FlattenSimpleInterpolation;
};

export const dropdownStyles: DropDownStyleType = {
  base: css`
    padding-left: 2rem;
    border-radius: 1rem;
    background-color: var(--color-white);
    border: 1px solid var(--color-dark);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    color: var(--color-dark);
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    order: 0;
    position: relative;

    & > :not(:last-child) {
      margin-right: 1rem;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      color: var(--color-dark);
      outline: none;
      border: 1px solid var(--color-primary);
    }
  `,
  medium: css`
    width: 38rem;
    height: 6rem;
  `,
  form: css`
    width: 100%;
  `,

  fullWidth: css`
    width: 100%;
    height: 6rem;
  `,

  error: css`
    width: 38rem;
    height: 6rem;
    border: 2px solid var(--color-red);
    outline: none;
    margin-bottom: 0.5rem;
  `,

  fullWidthError: css`
    width: 100%;
    height: 6rem;
    border: 2px solid var(--color-red);
    outline: none;
    margin-bottom: 0.5rem;
  `,

  divBase: css`
    margin-bottom: 2rem;
  `,

  bodyBase: css`
    background-color: var(--color-white);
    color: var(--color-dark);
    border: 1px solid var(--color-dark);
    width: 38rem;
    border-radius: 1rem;
    transition: all 0.4s;
    order: 1;
    max-height: 27rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 1rem;
      background-color: var(--color-white);
      border-radius: 0 1rem 1rem 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-grey);
      border-radius: 1rem;
    }

    &>: first-child {
      align-self: center;
    }
  `,

  bodyBase2: css`
    width: 100%;
  `,
  multiBodyBase: css`
    background-color: var(--color-white);
    color: var(--color-dark);
    border: 1px solid var(--color-dark);
    width: 100%;
    border-radius: 1rem;
    transition: all 0.4s;
    order: 1;
    max-height: 27rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 1rem;
      background-color: var(--color-white);
      border-radius: 0 1rem 1rem 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-grey);
      border-radius: 1rem;
    }

    &>: first-child {
      align-self: center;
    }
  `,

  iconBase: css`
    width: 6rem;
    height: 6rem;
    color: var(--color-dark);
    font-size: 3rem;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,

  multiDivBase: css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid var(--color-dark);
    border-radius: 1rem;
    padding: 1rem;
    min-height: 6rem;
    max-height: 12rem;
    overlow: scroll;
    padding-left: 2rem;
    background-color: var(--color-white);
    cursor: pointer;
    color: var(--color-dark);
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    order: 0;
    position: relative;

    & > :not(:last-child) {
      margin-right: 1rem;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      color: var(--color-dark);
      outline: none;
      border: 1px solid var(--color-primary);
    }
  `,
};
