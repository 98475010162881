import React from "react";
import styled from 'styled-components';
import { buttonStyles } from './styles'


interface FashButtonProps {
    title: string;
    icon?: React.ReactNode;
    color?: string;
    color2?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | ((arg: any) => void);
}


// Styled Icon Component
const IconSkeleton = styled.div<{ color: string; }>`
    ${buttonStyles.icon};
    ${(props) => props.color && `color: ${props.color}`};
`;

// Styled Title Component
const TitleSkeleton = styled.div<{ color?: string }>`
    ${buttonStyles.title};
    ${(props) => props.color && `background-color: ${props.color}`};
  
`;

// Styled Button Component
const ButtonSkeleton = styled.button<{ color?: string; color2?: string; }>`
    ${buttonStyles.baseButton};
    ${(props) => props.color2 && `background-color: ${props.color2}`};
    ${(props) => props.color && `border-color: ${props.color}`};
  
    &:hover ${IconSkeleton} {
        padding: 0rem 0.8rem 0rem 0.4rem;

    }
    &:hover ${TitleSkeleton} {
        background: transparent;
        ${(props) => props.color && `color: ${props.color}`};
    }

`;

// Main FashButton Component
const FashButton: React.FC<FashButtonProps> = ({
    title,
    icon,
    color = 'var(--color-primary)',  // default value for color
    color2 = 'var(--color-white)',        // default value for color2
    onClick,
}) => {



    return (
        <ButtonSkeleton color={color} color2={color2} onClick={onClick}>
            <TitleSkeleton color={color}>
                {title}
            </TitleSkeleton>
            {icon && <IconSkeleton color={color}>{icon}</IconSkeleton>}
        </ButtonSkeleton>
    );
};

export default FashButton;
