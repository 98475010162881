import { IoMdSettings, IoMdLogOut, IoMdEye, IoMdEyeOff, IoIosCloudDownload, IoMdRemoveCircleOutline, IoMdAddCircleOutline } from 'react-icons/io';
import { IconType } from 'react-icons';
import React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';

interface IconButtonProps {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  variant: 'settings' | 'logout' | 'eyeOpen' | 'eyeClose' | 'download' | 'add' | 'remove' | 'info';
  testID: string;
}

const iconMap: Record<IconButtonProps['variant'], IconType> = {
  settings: IoMdSettings,
  logout: IoMdLogOut,
  eyeOpen: IoMdEye,
  eyeClose: IoMdEyeOff,
  remove: IoMdRemoveCircleOutline,
  add: IoMdAddCircleOutline,
  download: IoIosCloudDownload,
  info: IoInformationCircleOutline,
};

const IconButton: React.FC<IconButtonProps> = ({ type = 'button', onClick, variant, testID = 'icon-button', ...props }) => {
  const IconComponent: IconType = iconMap[variant];

  if (!IconComponent) {
    return null;
  }

  return (
    <button onClick={onClick} className="text-[2.5rem] mr-8 cursor-pointer hover:text-color-fash-primary-2" data-testid={testID}>
      <IconComponent />
    </button>
  );
};

export default IconButton;
